<template>
  <span class="font-semibold text-lg text-BLACK-2"> {{ myText }}</span>
</template>
<script setup>
import { defineProps, ref } from "vue";

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
});

const myText = ref(props.text);
</script>
