<template>
  <y-card
    @click="$router.push(targetPage)"
    class="border-2 justify-center w-4/12 4xs:w-3/12"
  >
    <div class="flex flex-col items-center p-2 w-full">
      <img
        class="w-8/12 object-cover"
        :src="imageSrc"
        alt=""
        :class="customImgClass"
      />
      <span
        class="text-center sm:font-semibold p-1 sm:text-lg text-xs font-normal"
      >
        {{ CardTitle }}</span
      >
    </div>
  </y-card>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";

const props = defineProps({
  imgPath: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  customImgClass: {
    type: String,
    required: true,
  },
  targetPage: {
    type: String,
    required: true,
    default: "/home",
  },
});

const imageSrc = ref(require(`../assets/icons/${props.imgPath}`));
const CardTitle = ref(props.title);
</script>
