<template>
  <div :class="isSync ? 'w-1/3' : ''" class="flex justify-center py-6 mx-auto">
    <div class="flex flex-col justify-center items-center gap-2">
      <img class="p-4 rounded-lg shadow-lg" :src="imageSrc" alt="" />
      <p class="text-xs">{{ imageName }}</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";

const props = defineProps({
  imgPath: {
    type: String,
    required: true,
  },
  imgName: {
    type: String,
    required: true,
  },
  isSync: {
    type: Boolean,
    default: false,
    required: false,
  },
});
const imageSrc = ref(require(`../assets/${props.imgPath}`));
const imageName = ref(props.imgName);
const isSync = ref(props.isSync);
</script>
